import styled from 'styled-components';

export const FooterContainer = styled.div`
  padding: 20px 0;
  color: white;
  height: 100px;
  width: 100%;
  background-color: #002145;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    height: 250px;
  }
`;

// Logo and Slogan

export const LogoContainer = styled.div`
  margin: 0 3rem;
  flex-shrink: 1;
`;

export const LogoHeader = styled.img`
  width: 257px;
`;

export const LogoSlogan = styled.p`
  margin: 0;
  letter-spacing: 1px;
  font-size: 15px;
`;

// Subscribe to Newsletter Button

export const Button = (props) => {
  return <Link href={props.link}>{props.buttonText}</Link>;
};

export const Link = styled.a`
  margin: 0 3rem;
  text-decoration: none;
  background-color: #e5b13a;
  width: 271px;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
  color: white;
  padding: 10px 0;
  font-weight: 600;
  border-radius: 10px;
  border: 4px solid #e5b13a;

  // hover selector for Link
  &:hover {
    background-color: transparent;
  }
`;

// Socials and Contact Block

export const BottomFooterContainer = styled.div`
  margin: 0 3rem;
  width: 257px;
  text-align: center;
`;

export const BottomFooterEmail = styled.a`
  text-decoration: none;
  color: white;
  display: block;
  width: 100%;
  margin: 0 0px 0.5em 0px;
`;

export const BottomFooterCopyright = styled.p`
  margin: 0;
  font-size: 10px;
`;

export const IconImage = styled.img`
  height: 35px;
  margin: 0px 5px;
`;

export const Icon = (props) => {
  return (
    <a
      href={props.link}
      target='blank'
    >
      <IconImage src={props.icon} />
    </a>
  );
};
