import julieIbrahimovaImg from '../../assets/Team2024Images/julie_ibrahimova_exec.jpg';
import samanNiksiarImg from '../../assets/Team2024Images/saman_niksiar_exec.jpg';
import alexMalkowskiImg from '../../assets/Team2024Images/alex_malkowski_exec.jpg';
import chrisGriffithsImg from '../../assets/Team2024Images/chris_griffiths_adx.jpg';
import phoebeYanImg from '../../assets/Team2024Images/phoebe_yan_adx.jpg';
import samuelShinImg from '../../assets/Team2024Images/samuel_shin_bms.jpg';
import michaelLinImg from '../../assets/Team2024Images/michael_lin_btm.jpg';
import krishDattaImg from '../../assets/Team2024Images/krishan_datta_bms.jpg';
import evanBoileauImg from '../../assets/Team2024Images/evan_boileau_bus.jpg';
import juliaZhouImg from '../../assets/Team2024Images/julia_zhou_bus.jpg';
import aarjavJainImg from '../../assets/Team2024Images/aarjav_jain_emb.jpg';
import kyleGroulxImg from '../../assets/Team2024Images/kyle_groulx_emb.jpg';
import chrisPoovantanaImg from '../../assets/Team2024Images/chris_poovantana_stc.jpg';
import emilioPousAvilaImg from '../../assets/Team2024Images/emilio_pous_avila_stc.jpg';
import aumWaghImg from '../../assets/Team2024Images/aum_wagh_stc.jpg';
import joshuaRiefmanImg from '../../assets/Team2024Images/joshua_riefman_stg.jpg';
import rebeccaRathlefImg from '../../assets/Team2024Images/rebecca_rathlef_vdx.jpg';
import peregrinePichardoImg from '../../assets/Team2024Images/peregrine_pichardo_vdx.jpg';
import michelleHuImg from '../../assets/Team2024Images/michelle_hu_pas.jpg';
import prishaParikhImg from '../../assets/Team2024Images/prisha_parikh_btm.jpg';
import jasmineBianImg from '../../assets/Team2024Images/jasmine_bian_adx.jpg';
//TODO create an images.js file where we export all the photos

export const leadsExecs = [
  {
    name: 'Julie Ibrahimova',
    img: julieIbrahimovaImg,
    bio: "Hi everyone, I'm in my 3.5th year of Mechanical Engineering and have been part of UBC Solar since September 2022. I've gained experience in various aspects of solar car development, including battery systems, mechanical design, and project management. I served as the battery mechanical team lead for 8 months before becoming the team captain. This role has allowed me to lead, collaborate across engineering disciplines, and work closely with the business team. I’m excited to work with such a passionate team while learning and pushing the boundaries of sustainable technology.",
    title: 'Team Captain',
  },
  {
    name: 'Saman Niksiar',
    img: samanNiksiarImg,
    bio: "Hi, I'm Saman, and I am a fourth-year Electrical Engineering student, and I've been part of UBC Solar for nearly 2 years. This year, I'm excited to support the team, create a strong learning environment, and achieve even more from our solar car. It's going to be a great year of pushing our car's performance while collaborating with a talented group of people. I'm looking forward to seeing the innovative ideas come to life and the impact we can make together, both on the track and as a team!",
    title: 'Electrical Lead',
  },
  {
    name: 'Alex Malkowski',
    img: alexMalkowskiImg,
    bio: "My name is Alex, I'm in my third year of mechanical engineering, and I have been at UBC Solar for 3 years. This year I'm excited to work more closely with all the mechanical teams as we start designing UBC Solar's next-generation vehicle.",
    title: 'Mechanical Lead',
  },
];

export const leadsADX = [
  {
    name: 'Christopher Griffiths',
    img: chrisGriffithsImg,
    bio: "Hi, my name is Chris and I'm a fifth-year engineering physics student. I've been on the team for 2 years and I am one of the aerodynamics co-leads. I'm excited to work on improving our aerodynamics for future cars through iterative design. This means using SolidWorks for surface modelling and a repository of parameterized airfoils specifically chosen to encapsulate the rest of the car as aerodynamically as possible. I'm also looking forward to doing wind tunnel testing to validate the current car's simulated results and our CFD process!",
    title: 'Aerodynamics Team Co-lead',
  },
  {
    name: 'Phoebe Yan',
    img: phoebeYanImg,
    bio: "Hi! I'm Phoebe, a third year mechanical engineering student specializing in mechatronics. I joined UBC Solar in my first year, and have been blessed with so many fun and rewarding experiences (including driving our car Brightside on the highway!) My first 3 years were spent on the aeroshell team, where I gained a lot of experience with design and hands-on manufacturing. Switching my focus to aerodynamics, our team works on improving the performance and efficiency of our car through extensive research and design, using simulations and wind tunnel testing to validate our results. The aerodynamics team designs the part of the car that everyone sees, so it's pretty neat to see all of our work out on the track.",
    title: 'Aerodynamics Team Co-lead',
  },
  {
    name: 'Jasmine Bian',
    img: jasmineBianImg,
    bio: "Hi! My name is Jasmine and I'm a second-year mechanical engineering student. I joined UBC Solar at the start of my first year and began as a member on the aeroshell subteam, where I had the chance to dive into SolidWorks and help build the aeroshell for our current solar car Brightside. After a subteam restructure this year, I'm now an aerodynamics co-lead. Our team's current focus is designing a catamaran aeroshell for our next solar car, set to compete in 2027. I'm excited to be working with the team on improving the car's aerodynamics by using SolidWorks surfacing tools to design, CFD simulations to select and refine concepts, and wind tunnel testing to validate our results. It's awesome knowing our work will not only enhance performance but also shape the look of the car that represents our team on the track.",
    title: 'Aerodynamics Team Co-lead',
  },
];

export const leadsBMS = [
  {
    name: 'Samuel Shin',
    img: samuelShinImg,
    bio: 'Hey, my name is Samuel and I am the Battery Management Systems Co-lead. I am a second year Mechanical Engineering student trying to study Mechatronics, and I have been on the team for a year now. I joined because of my interest in sustainable energy fueled automobiles, and have not regretted the choice a single bit. This experience has given me an opportunity to learn professionalism as well as hands-on work which I really enjoyed. I am excited to better our system and compete in FSGP 2025 for a place on the podium!',
    title: 'Battery Management Systems Co-lead',
  },
  {
    name: 'Krish Datta',
    img: krishDattaImg,
    bio: "My name is Krish, I'm the BMS Co-lead going into my first year of mechatronics engineering. I joined Solar last year hoping to find a group of people as passionate about integrating mech and elec systems to build cool projects and I can't tell you enough how cool of an experience it has been. I'm quite excited for this year as we begin to redesign our BMS's fault enabling PCB and make our system faster!",
    title: 'Battery Management Systems Co-lead',
  },
];

export const leadsBTM = [
  {
    name: 'Michael Lin',
    img: michaelLinImg,
    bio: "Hi! I'm Michael, the battery mechanical lead at UBC Solar, and I've been on the team for 2 years now. This year, I'm really excited about designing the next battery for Solar!",
    title: 'Battery Mechanical Co-lead',
  },
  {
    name: 'Prisha Parikh',
    img: prishaParikhImg,
    bio: "Hey! This is Prisha. I'm the Battery Mechanical Co-lead and currently in my second year of Mechanical Engineering. I'm passionate about the automotive and sustainable energy industries, so I'm incredibly grateful for the opportunity to work on solar cars. I joined the team in my first year, and i's a decision I'm so glad I made:) I'm really excited to attend the competition this summer and to start designing the battery pack for the 2027 competition!",
    title: 'Battery Mechanical Co-lead',
  },
];

export const leadsBUS = [
  {
    name: 'Evan Boileau',
    img: evanBoileauImg,
    bio: "Hi! I am  a fourth year International Economics student at UBC. I've been part of UBC Solar since the start of 2023 and have been heading up finances and logistics as Business Co-Lead since December 2023. I’ve thoroughly enjoyed developing my leadership skills and working on financial and logistics based projects at UBC Solar. Over the next year I'm looking forward to connecting with more sponsors and helping prepare the team for FSGP 2025.",
    title: 'Business Team Co-lead',
  },
  {
    name: 'Julia Zhou',
    img: juliaZhouImg,
    bio: 'Hi, my name is Julia and I’m the Business Co-Lead at UBC Solar. I’m currently in my 5th year in Business, specializing in Marketing. I’m most excited about taking our car to competition again this coming summer and leveraging our existing community network as well as gaining more sponsors!',
    title: 'Business Team Co-lead',
  },
];

export const leadsEMB = [
  {
    name: 'Aarjav Jain',
    img: aarjavJainImg,
    bio: "Hey! I'm Aarjav, the Embedded Systems Co-Lead as of June 2024, and I have been on UBC Solar since September 2023. I am going into my 3rd year of CPEN, but currently I am on Co-op until September 2025 for a total of 16 months. I oversee Solar's Telemetry System, Motor Control System, and data acquisition with our Co-Lead Kyle. In terms of our goal for the coming years, I am excited to improve our telemetry systems long distance reliability, increase data transmission rates, obtain vehicle dynamics data, and add more safety checks for our electrical system!",
    title: 'Embedded Systems Co-lead',
  },
  {
    name: 'Kyle Groulx',
    img: kyleGroulxImg,
    bio: "Hey, I'm Kyle, one of the embedded software co-leads at UBC Solar. I'm a 4th-year electrical engineering student, and I've been part of the team since January 2022. UBC Solar has been a huge part of my university journey. Being a part of the team has helped me sharpen my technical skills and gain experience working in a fast paced team environment. This year, I'm especially excited to work on enhancing our telemetry system by boosting data transmission rates and long-distance communication. With real-time data collection, our team will be able to make better-informed design choices for the car, pushing us to be more competitive at FSGP 2025.",
    title: 'Embedded Systems Co-lead',
  },
];

export const leadsPAS = [
  {
    name: 'Michelle Hu',
    img: michelleHuImg,
    bio: "Hi! My name is Michelle and I'm a second-year electrical engineering student. I joined UBC Solar in my first year and learned so much more than I could have imagined. On PAS, we focus on both high-voltage and low-voltage systems, including the solar arrays, the motor, PCBs and more. This year, I'm super excited to be leading the redesign of our power and signal systems, aiming to compete in FSGP 2025 with an even more advanced car!",
    title: 'Power and Signals Lead',
  },
];

export const leadsSTC = [
  {
    name: 'Chris Poovantana',
    img: chrisPoovantanaImg,
    bio: 'My name is Chris Poovantana, and I’m currently in my fourth year of mechanical engineering. I joined the team in my second year, and it has been a rewarding experience working alongside senior colleagues and being involved in the full design process—from concept sketches to manufacturing. Looking ahead, I’m excited to implement major changes on Brightside 2025, particularly in weight reduction and redesigning auxiliary components. Additionally, I look forward to designing the new structural roll cage for 2026, running FEA simulations, and integrating it with the composite monocoque chassis.',
    title: 'Structures Team Co-lead',
  },
  {
    name: 'Emilio Pous Avila',
    img: emilioPousAvilaImg,
    bio: 'Hello, my name is Emilio. I am a fourth-year manufacturing engineering student, and I have been a member of UBC Solar for 2 years. I started in what originally was the aeroshell team and transitioned into being co-lead for the structures subteam. While I mostly focused on designing auxiliary parts, I got a lot of composite materials experience through hands on work manufacturing Brightside’s aeroshell. This year I am excited to be doing work on FEA for our car’s chassis, as well as integrating a new solar array on Brightside.',
    title: 'Structures Team Co-lead',
  },
  {
    name: 'Aum Wagh',
    img: aumWaghImg,
    bio: "I'm Aum, and I'm currently in grad school for Materials Engineering. I joined Solar in 2022, and I worked on the materials selection and manufacturing for Brightside's aeroshell. Within Structures, I'll be working on designing the composite chassis for our next car. I like being on Solar for the opportunity to learn engineering in a practical setting.",
    title: 'Structures Team Co-lead',
  },
];

export const leadsSTG = [
  {
    name: 'Joshua Riefman',
    img: joshuaRiefmanImg,
    bio: 'Hi! I’m a Third Year Engineering Physics student and I lead the Race Strategy team which is developing real-time simulations, models, and predictive algorithms of our solar cars in order to maximize their race performance. I’m really excited to work on some really cool and interesting problems—from software engineering to data science to physics—as we work on making sure our solar cars perform to the best of their abilities!',
    title: 'Strategy Team Lead',
  },
];

export const leadsVDX = [
  {
    name: 'Rebecca Rathlef',
    img: rebeccaRathlefImg,
    bio: "I am a second-year mechanical engineering student and co-lead of UBC Solar's Vehicle Dynamics (VDX) subteam. At VDX, we focus on optimizing our steering, braking, and suspension systems to ensure safety and performance during competition. Design teams offer an excellent opportunity to apply classroom concepts to real-world, hands-on challenges. Solar in particular is exciting because we face a unique set of constraints that guide our design process. On VDX, we apply concepts of dynamics, solid mechanics, and differential equations to solve problems related to vehicle stability, performance, and manufacturing. Outside of Solar I enjoy hiking, cooking, and crossovers between hiking and cooking.",
    title: 'Vehicle Dynamics Team Co-lead',
  },
  {
    name: 'Peregrine Pichardo',
    img: peregrinePichardoImg,
    bio: 'Hello! My name is Peregrine Pichardo. I’m one of the Co-lead of the Vehicle Dynamics sub-team. I’m in my fourth year of my Mechanical Engineering degree and I’ve been in UBC Solar for three years now and counting. I’m looking forward to making the transition from a four-wheel suspension into a three-wheel suspension, it’s going to be a new challenge but I’m looking forward to the change it will bring!',
    title: 'Vehicle Dynamics Team Co-lead',
  },
];
