import React from 'react';
import {
  FooterContainer,
  LogoContainer,
  LogoHeader,
  LogoSlogan,
  Button,
  BottomFooterContainer,
  BottomFooterEmail,
  BottomFooterCopyright,
  Icon,
} from './FooterStyle';

import FacebookLogo from '../../assets/Footer/Facebook.svg';
import InstagramLogo from '../../assets/Footer/Instagram.svg';
import LinkedInLogo from '../../assets/Footer/LinkedIn.svg';
import YouTubeLogo from '../../assets/Footer/YouTube.svg';
import EmailLogo from '../../assets/Footer/Email3.png';
import SolarLogo from '../../assets/Footer/ubcsolar2_white_gold.png';

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <LogoContainer>
          <LogoHeader src={SolarLogo}></LogoHeader>
          <LogoSlogan>
            <span style={{ color: '#E5B13A' }}>Solar Powered.</span> Student
            Driven.
          </LogoSlogan>
        </LogoContainer>
        <BottomFooterContainer>
          <BottomFooterEmail href='mailto:manager@ubcsolar.com'>
            manager@ubcsolar.com
          </BottomFooterEmail>
          <Icon
            link='https://www.facebook.com/UbcSolar/'
            icon={FacebookLogo}
          ></Icon>
          <Icon
            link='https://www.instagram.com/ubcsolar/'
            icon={InstagramLogo}
          ></Icon>
          <Icon
            link='https://www.linkedin.com/company/ubc-solar/'
            icon={LinkedInLogo}
          ></Icon>
          <Icon
            link='https://www.youtube.com/channel/UCgPR5tgh2dBeRPH7jfFDpyA'
            icon={YouTubeLogo}
          ></Icon>
          {/* <Icon
            link='mailto:manager@ubcsolar.com'
            icon={EmailLogo}
          ></Icon> */}
          <BottomFooterCopyright>
            &copy; 2025 UBC Solar. All rights reserved.
          </BottomFooterCopyright>
        </BottomFooterContainer>
        <Button
          link='http://eepurl.com/gnnmoH'
          buttonText='Subscribe To Our Newsletter'
        />
      </FooterContainer>
    </>
  );
};

export default Footer;
