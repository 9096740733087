import React, { useState } from 'react';
import NavigationBar from '../../components/NavigationBar';
import Sidebar from '../../components/Sidebar';

import HeroVideo from '../../assets/HomePageImages/HeroVideo.mp4';
import SolarWheel from '../../assets/HomePageImages/SolarWheel.png';
import { HeroOverlay } from '../HomePage/HomePageStyle';

import Footer from '../Footer';
import styled from 'styled-components';
import {
  HomeContainer,
  HomeHeroContainer,
  SolarLogo,
  SolarMotto,
  Anchor,
  VideoContainer,
  Video,
  PopupContainer,
  PopupContent,
} from './HomePageStyle';

// New TextBox styled component for the additional text
const TextBox = styled.div`
  margin: 20px auto;
  padding: 20px;
  max-width: 80%;
  text-align: center;
  background-color: #f8f8f8;
  border-radius: 8px;
`;

const HomePage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(true);
  
  // Create a styled component for the yellow banner
  const YellowBanner = styled.div`
    width: 100%;
    height: 2in; /* Adjust the height as needed */
    background-color: #e5b13b ; 
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem; /* Adjust font-size as needed */
    font-weight: bold;
    color: black; /* Text color that contrasts well with yellow */
  `;
  


const Title = styled.h2`
  /* "Our Mission" */
  font-size: 2.5rem;
  margin: 0;
  margin-bottom: 20px;
  font-weight: 700; 
`;

const Subtitle = styled.h3`
  /* "Educate. Innovate. Challenge." */
  font-size: 1.5rem;
  font-weight: 400; 
  margin: 0;
`;

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const closePopup = () => {
    setShowPopup(false);
  };
  const Video = styled.div`
  width: 85%;
  height: 85%;
`;
  // useEffect(() => {
  //   const timer = setTimeout(() => setShowPopup(false), 100000);
  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <>
      <NavigationBar isOpen={isOpen} toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />

      {showPopup && (
        <PopupContainer>
          <PopupContent>
            <h2>Support Our Crowdfunding Campaign!</h2>
            <a
              href="https://crowdfundraising.ubc.ca/projects/ubc-solar/"
              target="_blank"
              rel="noopener noreferrer"
              className="popup-link"
            >
              Donate Now
            </a>
            <button onClick={closePopup} className="popup-close-btn">
              Close
            </button>
          </PopupContent>
        </PopupContainer>
      )}

      <HomeContainer>
        <HomeHeroContainer>
          <HeroOverlay>
            <video autoPlay loop muted playsInline>
              <source src={HeroVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </HeroOverlay>
          <SolarLogo src={SolarWheel} />
          <SolarMotto>
            <span style={{ color: '#E5B13A' }}>Solar Powered</span> Student Driven
          </SolarMotto>
        </HomeHeroContainer>
        <Anchor className="meet" />
        <VideoContainer>
          <Video>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/AU9i4J8Gctc?si=aourx_usa4u3vYjU"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </Video>
        </VideoContainer>

     
      </HomeContainer>

      <Footer />
    </>
  );
};

export default HomePage;