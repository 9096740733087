import Raven1 from '../../assets/ProjectPageImages/RavenGallery1.JPG';
import Raven2 from '../../assets/ProjectPageImages/RavenGallery2.JPG';
import Raven3 from '../../assets/ProjectPageImages/RavenGallery3.JPG';
import Raven4 from '../../assets/ProjectPageImages/RavenGallery4.JPG';
import Raven5 from '../../assets/ProjectPageImages/RavenGallery5.JPG';
import Daybreak1 from '../../assets/ProjectPageImages/DaybreakGallery1.jpg';
import Daybreak2 from '../../assets/ProjectPageImages/DaybreakGallery2.jpg';
import Daybreak3 from '../../assets/ProjectPageImages/DaybreakGallery3.JPG';
import Daybreak4 from '../../assets/ProjectPageImages/DaybreakGallery4.jpg';
import Daybreak5 from '../../assets/ProjectPageImages/DaybreakGallery5.jpg';
import Brightside1 from '../../assets/ProjectPageImages/Brightside1.jpg';
import Brightside2 from '../../assets/ProjectPageImages/Brightside2.jpg';
import Brightside3 from '../../assets/ProjectPageImages/Brightside3.jpg';
import Brightside4 from '../../assets/ProjectPageImages/Brightside4.jpg';
import Brightside5 from '../../assets/ProjectPageImages/Brightside5.jpeg';
import Material from '../../assets/ProjectPageImages/Material.png';
import Battery from '../../assets/ProjectPageImages/Battery.png';
import Gears from '../../assets/ProjectPageImages/Gears.png';
import Frame from '../../assets/ProjectPageImages/Frame.png';
import Solar from '../../assets/ProjectPageImages/Solar.png';
// import MaterialFill from '../../assets/ProjectPageImages/MaterialF.png';
// import BatteryFill from '../../assets/ProjectPageImages/BatteryF.png';
// import GearsFill from '../../assets/ProjectPageImages/GearsF.png';
// import FrameFill from '../../assets/ProjectPageImages/FrameF.png';
// import SolarFill from '../../assets/ProjectPageImages/SolarF.png';

export const projectsData = [
  {
    name: 'Brightside',
    date: '2022-Present',
    specs: [
      '4m2 of Maxeon Gen3 Ne3 arrays (24% efficiency)',
      'Mitsuba M2096-D3 motor (2kW nominal, 5kW peak)',
      '20 kg 5.24kWh Li-ion Sanyo NCR18650GA cells',
      'AISI 4130 chromoly steel Chassis',
      'Divinycell Foam Core +  Twill Weave Carbon Fibre',
    ],
    icons: [Solar, Gears, Battery, Frame, Material],
    imgs: [Brightside1, Brightside2, Brightside3, Brightside4, Brightside5],
  },
  {
    name: 'DAYBREAK',
    date: '2017-2022',
    specs: [
      '3.8m2 of SunPower E60 arrays (23.9% Efficiency)',
      'NGM SC100 motor (3.5kW Nominal, 7.5kW Peak)',
      '20 kg 5.14kWh Li-ion Panasonic NCR18650b cells',
      'AISI 4130 chromoly steel chassis',
      'Soric Core + Twill Weave Carbon Fibre',
    ],
    icons: [Solar, Gears, Battery, Frame, Material],
    imgs: [Daybreak1, Daybreak2, Daybreak3, Daybreak4, Daybreak5],
  },
  {
    name: 'RAVEN',
    date: '2007-2017',
    specs: [
      '6m2 of SunPower C60 arrays (22.4% efficiency)',
      'PERM PMG 132 motor (14.2 kW peak)',
      '20kg 3.17 kWh Li-ion Polymer EIG ePLB C020 cells',
      'AISI 4130 chromoly steel chassis',
      'Soric Core + Fibreglass, carbon fibre and kevlar weave layers',
    ],
    icons: [Solar, Gears, Battery, Frame, Material],
    imgs: [Raven1, Raven2, Raven3, Raven4, Raven5],
  },
];
