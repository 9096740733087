import styled from 'styled-components';
import HeroImage from '../../assets/HomePageImages/HeroImage.jpg';

export const HomeContainer = styled.div``;

export const HomeHeroContainer = styled.div`
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  @media (max-width: 800px), (max-height: 600px) {
    video {
      display: none;
    }
    background-image: url(${HeroImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  /* // height: 600px; */
  height: 100vh;
  padding-top: 20px;
`;

export const Video = styled.div`
  width: 1333px;
  height: 750px;
`;

export const HeroOverlay = styled.div`
  position: absolute;
  background: rgba(0, 33, 69, 0.7);
  background-size: cover;
  width: 100vw;
  height: calc(100vh - 60px);
  display: absolute;
`;

export const SolarLogo = styled.img`
  height: 42vh;
  z-index: 1;
`;

export const SolarMotto = styled.p`
  margin: 1vh;
  letter-spacing: 1px;
  font-size: 6vh;
  font-weight: 600;
  color: white;
  z-index: 1;
`;

export const NavBtn = styled.nav`
  display: flex;
  z-index: 1;
  align-items: center;
`;

export const NavBtnLink = styled.div`
  border-radius: 50px;
  white-space: nowrap;
  color: white;
  font-size: 30px;
  font-weight: 600;
  outline: none;
  padding: 10px 22px;
  margin-top: 20px;
  border: 5px solid #e5b13a;
  cursor: pointer;
  transition: all 0.5s ease-out;
  text-decoration: none;

  &:hover {
    background-position: left bottom;
    background: #e5b13a;
  }
  @media screen and (max-width: 900px) {
    font-size: calc(10px + 1.5vw);
    padding: calc(8px + 0.5vw) calc(16px + 1vw);
    border: calc(2px + 0.5vw) solid #e5b13a;
  }
`;

export const Anchor = styled.div`
  display: block;
  position: relative;
  visibility: hidden;
  top: -60px;
  align-items: center;
  justify-content: right;
`;

export const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);

  & a {
    display: inline-block;
    margin: 1rem 0;
    padding: 0.5rem 1rem;
    background-color: #e5b13a;
    font-weight: 600;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }

  & a:hover {
    color: black;
    transition: 0.5s;
  }

  & button {
    background: transparent;
    border: none;
    font-size: 1rem;
    color: #555;
    cursor: pointer;
    margin-top: 1rem;
  }

  & button:hover {
    color: #e5b13a;
  }
`;
